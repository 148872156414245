<template>
  <div class="main-page">
    <h1 class="page-title">
      同意事項
    </h1>
    <div class="page-content">
      <ul class="list-instructions">
        <li>
          <p class="text-jp">
            以下内容に同意いただけましたら「同意して次へ」を押してください
          </p>
        </li>
      </ul>
      <section class="section-category">
        <h2 class="title-item green">
          <span class="jp">同意事項</span>
        </h2>
        <!-- アプリケーション固有の設定 -->
        <div class="content">
          <div class="agreements-list">
            <!-- 3 -->
            <p v-if="forms.select4">
              《印鑑登録証明書について》<br>
              ■証明書の発行には印鑑登録証のご提示が必要です。
            </p>
            <!-- 4 -->
            <p v-if="forms.select5">
              《住民票について》<br>
              ■武豊町以外の住民票を請求される場合は、窓口職員に申し出てください。<br>
              ■住所の履歴等が必要な場合は窓口職員に申し出てください。
            </p>
            <p>
              <span v-if="forms.select4 || forms.select5">《その他》<br></span>
              ■届出の際には、本人確認書類を提示してください。 <br>
              （本人確認書類：免許証、パスポート、マイナンバーカード、保険証、住基カード、年金手帳、学生証、在留カードなど）<br>
              ※顔写真の​ない身分証明書の場合2点以上お持ちください<br>
              ■代理人による申請の場合委任状が必要です。 <br>
              例） <br>
              ・異動届・・・あなた以外が異動される場合 <br>
              ・証明書・・・あなた以外の証明書が必要な場合 <br>
              ※委任状のサンプルは<a
                href="https://www.town.taketoyo.lg.jp/kurashi/1001501/1001556/1001611.html"
                target="_blank"
              >こちら</a>をご確認ください。<br>
              ■同一のお手続きで複数のご請求がある場合は、お手数ですがトップ画面から再度入力をお願いします。<br>
              ■虚偽届出をしたときは、法に基づき刑罰の対象となります。<br>
              <br>
              <span style="color:red;">■画面入力後、窓口でのお手続きが必要です。入力後に表示されるQRコードをあなた様ご自身にて窓口へご提示ください。</span>
            </p>
          </div>
        </div>
      </section>
      <div class="list-button">
        <button
          type="button"
          class="button trans"
          @click="changeRouter('selection')"
        >
          <span class="txt">戻る</span>
        </button>
        <button
          type="button"
          class="button trans"
          @click="changeRouter('form')"
        >
          <span class="txt">同意して次へ</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agreements',
  data() {
    return {
      forms: {}
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.forms = this.$store.state.selection;
  },
  methods: {
    /**
     * Change router
     * @param {string} name - name of router to change
     */
    changeRouter: function (name) {
      this.$router.push({ name: name });
    },
  },
};
</script>
