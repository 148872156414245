<template>
  <div>
    <div class="main-page">
      <h1 class="page-title">
        申請書選択
      </h1>
      <div class="page-content">
        <ul class="list-instructions">
          <li>
            <p class="text-jp">
              申請内容を選択し「次へ」を押してください（複数可）
            </p>
          </li>
        </ul>
        <section class="section-category">
          <h2 class="title-item green">
            <span class="jp">
              申請内容
              <span class="small">（必須項目）</span>
            </span>
          </h2>
          <div class="content">
            <p class="desc">
              以下より選択してください（複数選択可）
            </p>
            <!-- アプリケーション固有の設定 -->
            <div class="list-checkbox">
              <div class="item">
                <label class="item-checkbox">
                  <input
                    v-model="forms.select2"
                    type="checkbox"
                    :value="true"
                  >
                  <span class="text-label border-dark">
                    <span class="jp">住民異動届</span>
                  </span>
                </label>
              </div>
              <div class="item">
                <label class="item-checkbox">
                  <input
                    v-model="forms.select4"
                    type="checkbox"
                    :value="true"
                  >
                  <span class="text-label border-dark">
                    <span class="jp">印鑑登録証明書</span>
                  </span>
                </label>
              </div>
              <div class="item">
                <label class="item-checkbox">
                  <input
                    v-model="forms.select5"
                    type="checkbox"
                    :value="true"
                  >
                  <span class="text-label border-dark">
                    <span class="jp">住民票写し等</span>
                  </span>
                </label>
              </div>
              <div class="item">
                <label class="item-checkbox">
                  <input
                    v-model="forms.select6"
                    type="checkbox"
                    :value="true"
                  >
                  <span class="text-label border-dark">
                    <span class="jp">戸籍証明書等</span>
                  </span>
                </label>
              </div>
              <div class="item">
                <label class="item-checkbox">
                  <input
                    v-model="forms.select7"
                    type="checkbox"
                    :value="true"
                  >
                  <span class="text-label border-dark">
                    <span class="jp">税証明書等</span>
                  </span>
                </label>
              </div>
            </div>
            <!-- //アプリケーション固有の設定 -->
            <p
              v-if="isTouched && !isValid()"
              class="alert-text"
            >
              <span class="jp">エラー：選択してください</span>
            </p>
          </div>
        </section>
        <div class="block-text-final-desc">
          <p>※上記以外は窓口の申請書にてお手続きをお願いします</p>
        </div>
        <button
          type="button"
          class="button trans"
          @click="next()"
        >
          <span class="txt">次へ</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Selection',
  data() {
    return {
      forms: {},
      isTouched: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.forms = this.$store.state.selection;
  },
  methods: {
    // validating form input
    isValid() {
      return this.forms.select2 || this.forms.select4 || this.forms.select5 || this.forms.select6 || this.forms.select7; //アプリケーション固有の設定
    },
    next() {
      this.isTouched = true;
      if (this.isValid()) {
        if(this.forms.select4 || this.forms.select5 || this.forms.select6 || this.forms.select7) this.forms.select3 = true;
        else this.forms.select3 = false;
        this.$router.push({ name: 'agreements' });
        this.$store.state.selection = this.forms;
      }
    },
  },
};
</script>
